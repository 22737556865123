import React, { useEffect, useState } from 'react';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // Importer les styles de tippy.js

const Profession = () => {
    return (
      <div className="professions">
        <p>Fonctionnalité à venir...</p>
      </div>
    );
  };
  
  export default Profession;

